<template>
  <div>
    <div id="settingsContainer">
      <div class="p-grid p-nogutter">
        <div class="p-col">
          <div class="settingsBar">Settings</div>
        </div>
      </div>
    </div>

    <div class="secondBox">
      <div class="p-field p-grid">
        <label for="settingProject" class="p-col-fixed" style="width: 140px">Project</label>
        <div class="p-col">
          <InputText
            id="settingProject"
            aria-describedby="settingProject-help"
            type="number"
            style="width: 240px ; border-radius: 5px"
            v-model="settingProjectComputed"
            :disabled="disabledProjectOrdinal"
          />
        </div>
        <small
          id="settingProject-help"
          class="p-invalid"
          v-show="classList.projectNumberClass!==''"
        >Project Number cannot be empty.</small>
      </div>

      <div class="p-field p-grid">
        <label for="settingOrdinal" class="p-col-fixed" style="width: 140px">Ordinal</label>
        <div class="p-col">
          <InputText
            id="settingOrdinal"
            aria-describedby="settingOrdinal-help"
            type="number"
            style="width: 240px ; border-radius: 5px"
            v-model="settingOrdinalComputed"
            :disabled="disabledProjectOrdinal"
          />
        </div>
        <small
          id="settingOrdinal-help"
          class="p-invalid"
          v-show="classList.ordinalNumberClass!==''"
        >Ordinal number cannot be empty.</small>
      </div>
      <div class="p-field p-grid">
        <div
          v-show="showProjectOrdinalMessage"
          style="color:red"
        >Monitor with this project and ordinal number already exists.</div>
      </div>
    </div>

    <div class="secondBox">
      <div class="p-field p-grid">
        <label for="settingLabel" class="p-col-fixed" style="width: 140px">Label</label>
        <div class="p-col">
          <InputText
            id="settingLabel"
            type="text"
            style="width: 240px ; border-radius: 5px"
            v-model="settingLabelComputed"
          />
        </div>
      </div>

      <div class="p-field p-grid">
        <label for="settingLocation" class="p-col-fixed" style="width: 140px">Location</label>
        <div class="p-col">
          <InputText
            id="settingLocation"
            type="text"
            style="width: 240px ; border-radius: 5px"
            v-model="settingLocationComputed"
          />
        </div>
      </div>
    </div>

    <div class="secondBox">
      <div class="p-field p-grid">
        <label for="settingGroundFaultAs" class="p-col-fixed" style="width: 140px">Ground fault as</label>
        <div class="p-col">
          <select
            id="settingGroundFaultAs"
            type="text"
            class="dropdownBar"
            v-bind:style="{'background-color':colorGroundFault}"
            v-on:change="changeColorGroundFault"
            v-model="settingGroundFaultAsComputed"
          >
            <option>Warning</option>
            <option>Error</option>
          </select>
        </div>
      </div>

      <div class="p-field p-grid">
        <label for="settingShortCircuitAs" class="p-col-fixed" style="width: 140px">Short circuit as</label>
        <div class="p-col">
          <select
            id="settingShortCircuitAs"
            type="text"
            class="dropdownBar"
            v-bind:style="{'background-color':colorShortCircuit}"
            v-on:change="changeColorShortCircuit"
            v-model="settingShortCircuitAsComputed"
          >
            <option>Warning</option>
            <option>Error</option>
          </select>
        </div>
      </div>

      <div class="p-field p-grid">
        <label for="settingOfflineAs" class="p-col-fixed" style="width: 140px">Device offline as</label>
        <div class="p-col">
          <select
            id="settingOfflineAs"
            type="text"
            class="dropdownBar"
            v-bind:style="{'background-color':colorDeviceOffline}"
            v-on:change="changeColorDeviceOffline"
            v-model="settingOfflineAsComputed"
          >
            <option>Warning</option>
            <option>Error</option>
          </select>
        </div>
      </div>
    </div>

    <div class="secondBox">
      <div class="p-field p-grid">
        <label for="settingGroundFaultAs" class="p-col-fixed" style="width: 140px">Show Voltage in</label>
        <div class="p-col">
          <select
            id="settingVoltageUnit"
            type="text"
            class="dropdownBar"
            v-model="settingVoltageUnitComputed"
          >
            <option>V</option>
            <option>kV</option>
          </select>
        </div>
      </div>
    </div>

    <div class="buttonBox">
      <Button
        v-if="showRemoveBtn"
        label="Remove"
        class="p-button-danger"
        id="button-danger"
        @click="deleteConditionMonitor()"
      />
      <Button
        label="Close"
        class="p-button-secondary"
        id="button-remove"
        @click="closeSettingDialog"
      />
      <Button label="Save" id="button-save" @click="submitSetting" />
    </div>
  </div>
</template>


<script>
export default {
  components: {},
  data() {
    return {
      colorGroundFault: "red",
      colorShortCircuit: "red",
      colorDeviceOffline: "red",
      classList: {
        projectNumberClass: "",
        ordinalNumberClass: ""
      }
    };
  },
  props: {
    settingProject: {
      default: "",
      type: String
    },
    settingOrdinal: {
      default: "",
      type: String
    },
    settingMeasurement: {
      default: "",
      type: String
    },
    settingLabel: {
      default: "",
      type: String
    },
    settingLocation: {
      default: "",
      type: String
    },
    settingGroundFaultAs: {
      default: "Error",
      type: String
    },
    settingShortCircuitAs: {
      default: "Error",
      type: String
    },
    settingOfflineAs: {
      default: "Error",
      type: String
    },
    settingVoltageUnit: {
      default: "kV",
      type: String
    },
    showRemoveBtn: {
      default: true,
      type: Boolean
    },
    showProjectOrdinalMessage: {
      type: Boolean,
      default: false
    },
    disabledProjectOrdinal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    settingProjectComputed: {
      set(val) {
        this.$emit("settingProjectEmit", val);
      },
      get() {
        return this.settingProject;
      }
    },
    settingOrdinalComputed: {
      set(val) {
        this.$emit("settingOrdinalEmit", val);
      },
      get() {
        return this.settingOrdinal;
      }
    },
    settingLabelComputed: {
      set(val) {
        this.$emit("settingLabelEmit", val);
      },
      get() {
        return this.settingLabel;
      }
    },
    settingLocationComputed: {
      set(val) {
        this.$emit("settingLocationEmit", val);
      },
      get() {
        return this.settingLocation;
      }
    },
    settingGroundFaultAsComputed: {
      set(val) {
        this.$emit("settingGroundFaultAsEmit", val);
      },
      get() {
        return this.settingGroundFaultAs;
      }
    },
    settingShortCircuitAsComputed: {
      set(val) {
        this.$emit("settingShortCircuitAsEmit", val);
      },
      get() {
        return this.settingShortCircuitAs;
      }
    },
    settingOfflineAsComputed: {
      set(val) {
        this.$emit("settingOfflineAsEmit", val);
      },
      get() {
        return this.settingOfflineAs;
      }
    },
    settingVoltageUnitComputed: {
      set(val) {
        this.$emit("settingVoltageUnitEmit", val);
      },
      get() {
        return this.settingVoltageUnit;
      }
    },
    settingMeasurementComputed: {
      set(val) {
        this.$emit("settingMeasurementEmit", val);
      },
      get() {
        return this.settingMeasurement;
      }
    }
  },
  methods: {
    closeSettingDialog() {
      this.$emit("closeSettingDialogEmit", false);
    },
    validateForm() {
      this.settingProject === ""
        ? (this.classList.projectNumberClass = "p-invalid")
        : (this.classList.projectNumberClass = "");
      this.settingOrdinal === ""
        ? (this.classList.ordinalNumberClass = "p-invalid")
        : (this.classList.ordinalNumberClass = "");

      return !(this.settingProject === "" || this.settingOrdinal === "");
    },
    submitSetting() {
      if (this.validateForm()) this.$emit("submitSettingEmit");
    },
    deleteConditionMonitor() {
      this.$emit("deleteConditionMonitorEmit");
    },
    changeColorGroundFault: function() {
      if (this.settingGroundFaultAs === "Error") {
        this.colorGroundFault = "red";
      } else {
        this.colorGroundFault = "orange";
      }
    },
    changeColorShortCircuit: function() {
      if (this.settingShortCircuitAs === "Error") {
        this.colorShortCircuit = "red";
      } else {
        this.colorShortCircuit = "orange";
      }
    },
    changeColorDeviceOffline: function() {
      if (this.settingOfflineAs === "Error") {
        this.colorDeviceOffline = "red";
      } else {
        this.colorDeviceOffline = "orange";
      }
    }
  }
};
</script>


<style scoped>
#settingsContainer {
  width: 480px;
  border: 1px solid black;
}

.settingsBar {
  width: 80px;
  padding: 15px;
  text-align: left;
  font-weight: bold;
  font-size: 25px;
}


.secondBox {
  width: 480px;
  border-width: 0 1px 1px 1px;
  border-color: #2c3e50;
  border-style: solid;
  text-align: center;
  padding-left: 20px;
  padding-top: 15px;
}
.secondBox span {
  background: blue;
}

.dropdownBar {
  width: 240px;
  height: 35px;
  border-radius: 5px;
}

.buttonBox {
  width: 480px;
  border-width: 0 1px 1px 1px;
  border-color: #2c3e50;
  border-style: solid;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 10px;
}

#button-danger {
  width: 120px;
  margin-right: 30px;
}

#button-remove {
  width: 120px;
  margin-right: 30px;
}

#button-save {
  width: 120px;
}
</style>