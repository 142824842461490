<template>
  <!--Parent Component-->
  <div>
    <div class="p-grid">
      <div v-for="(conditionMonitor,idx) in getConditionMonitorList" v-bind:key="idx">
        <div class="p-col">
          <ConditionMonitorComponent
            :componentNumber="idx"
            @emitShowSettings="emitShowSettings"
            :status="conditionMonitor.status"
            :streamData="filteredStreamData(conditionMonitor.settingProject, conditionMonitor.settingOrdinal)"
            :conditionMonitorSetting="filteredSetting(conditionMonitor.settingProject, conditionMonitor.settingOrdinal)"
            :label="filteredSetting(conditionMonitor.settingProject, conditionMonitor.settingOrdinal).settingLabel"
            :location="filteredSetting(conditionMonitor.settingProject, conditionMonitor.settingOrdinal).settingLocation"
          />
        </div>
      </div>
    </div>

    <div class="float">
      <span>
        <SpeedDial
          icon="pi pi-plus"
          buttonClassName="p-button-success"
          :radius="120"
          type="quarter-circle"
          @click="showAddConditionMonitor = true"
        />
      </span>
    </div>

    <Dialog
      header="Edit Condition Monitor"
      :visible.sync="showSettingDialog"
      :modal="true"
      class="headerClass"
    >
      <SettingsComponent
        :conditionMonitorSetting="selectedConditionMonitor"
        :settingProject="selectedConditionMonitor.settingProject"
        :settingOrdinal="selectedConditionMonitor.settingOrdinal"
        :settingLabel="selectedConditionMonitor.settingLabel"
        :settingLocation="selectedConditionMonitor.settingLocation"
        :settingGroundFaultAs="selectedConditionMonitor.settingGroundFaultAs"
        :settingShortCircuitAs="selectedConditionMonitor.settingShortCircuitAs"
        :settingOfflineAs="selectedConditionMonitor.settingOfflineAs"
        :settingVoltageUnit="selectedConditionMonitor.settingVoltageUnit"
        :settingMeasurement="selectedConditionMonitor.settingMeasurement"
        :showProjectOrdinalMessage="showProjectOrdinalMessage"
        :disabledProjectOrdinal="true"
        @settingProjectEmit="settingProjectEmitUOp"
        @settingOrdinalEmit="settingOrdinalEmitUOp"
        @settingLabelEmit="settingLabelEmitUOp"
        @settingLocationEmit="settingLocationEmitUOp"
        @settingGroundFaultAsEmit="settingGroundFaultAsEmitUOp"
        @settingShortCircuitAsEmit="settingShortCircuitAsEmitUOp"
        @settingOfflineAsEmit="settingOfflineAsEmitUOp"
        @settingVoltageUnitEmit="settingVoltageUnitEmitUOp"
        @settingMeasurementEmit="settingMeasurementEmitUOp"
        @submitSettingEmit="updateConditionMonitorEmit"
        @closeSettingDialogEmit="closeSettingDialogEmit"
        @deleteConditionMonitorEmit="deleteConditionMonitorEmit"
      />
    </Dialog>

    <Dialog
      header="Add Condition Monitor"
      :visible.sync="showAddConditionMonitor"
      :modal="true"
      class="headerClass"
    >
      <SettingsComponent
        :showRemoveBtn="false"
        :settingProject="newConditionMonitor.settingProject"
        :settingOrdinal="newConditionMonitor.settingOrdinal"
        :settingLabel="newConditionMonitor.settingLabel"
        :settingLocation="newConditionMonitor.settingLocation"
        :settingGroundFaultAs="newConditionMonitor.settingGroundFaultAs"
        :settingShortCircuitAs="newConditionMonitor.settingShortCircuitAs"
        :settingOfflineAs="newConditionMonitor.settingOfflineAs"
        :settingVoltageUnit="newConditionMonitor.settingVoltageUnit"
        :settingMeasurement="newConditionMonitor.settingMeasurement"
        :showProjectOrdinalMessage="showProjectOrdinalMessage"
        @settingProjectEmit="settingProjectEmitCOp"
        @settingOrdinalEmit="settingOrdinalEmitCOp"
        @settingLabelEmit="settingLabelEmitCOp"
        @settingLocationEmit="settingLocationEmitCOp"
        @settingGroundFaultAsEmit="settingGroundFaultAsEmitCOp"
        @settingShortCircuitAs="settingShortCircuitAsEmitCOp"
        @settingOfflineAsEmit="settingOfflineAsEmitCOp"
        @settingVoltageUnitEmit="settingVoltageUnitEmitCOp"
        @settingMeasurementEmit="settingMeasurementEmitCOp"
        @submitSettingEmit="addConditionMonitorEmit"
        @closeSettingDialogEmit="closeAddConditionMonitorEmit"
      />
    </Dialog>
  </div>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import ConditionMonitorComponent from "../components/ConditionMonitorComponent";
import SettingsComponent from "../components/SettingsComponent";

export default {
  components: {
    ConditionMonitorComponent,
    SettingsComponent
  },
  data() {
    return {
      selectedConditionMonitor: {
        settingProject: "",
        settingOrdinal: "",
        settingLabel: "",
        settingLocation: "",
        settingGroundFaultAs: "",
        settingShortCircuitAs: "",
        settingOfflineAs: "",
        settingVoltageUnit: "",
        settingMeasurement: ""
      },
      newConditionMonitor: {
        settingProject: "",
        settingOrdinal: "",
        settingLabel: "",
        settingLocation: "",
        settingGroundFaultAs: "Error",
        settingShortCircuitAs: "Error",
        settingOfflineAs: "Error",
        settingVoltageUnit: "kV",
        settingMeasurement: ""
      },
      showAddConditionMonitor: false,
      showSettingDialog: false,
      showProjectOrdinalMessage: false,
      menuItems: [
        {
          label: "Delete",
          icon: "pi pi-trash",
          command: () => {}
        },
        {
          label: "Update",
          icon: "pi pi-pencil",
          command: () => {}
        },
        {
          label: "Add",
          icon: "pi pi-plus",
          command: () => {
            this.showAddConditionMonitor = true;
          }
        }
      ]
    };
  },
  computed: {
    ...mapGetters("ConditionMonitorStore", [
      "getConditionMonitorList",
      "getData"
    ]),
    streamDataComputed() {
      return this.$store.getters.data;
    }
  },
  methods: {
    ...mapActions("ConditionMonitorStore", [
      "GET_ALL_CONDITION_MONITOR_SETTINGS",
      "UPDATE_CONDITION_MONITOR",
      "ADD_CONDITION_MONITOR",
      "DELETE_CONDITION_MONITOR_BY_ID"
    ]),
    closeSettingDialogEmit() {
      this.showSettingDialog = false;
    },
    closeAddConditionMonitorEmit() {
      this.showAddConditionMonitor = false;
    },
    emitShowSettings(data) {
      this.showSettingDialog = data.value;
      this.selectedConditionMonitor = data.conditionMonitorSetting;
    },
    filteredStreamData(projectNumber, ordinalNumber) {
      let filteredProjectNumberList = this.streamDataComputed.filter(
        conditionMonitor => conditionMonitor.projectNumber === projectNumber
      );
      let filteredOrdinalNumber = filteredProjectNumberList.filter(
        filteredProjectNumber =>
          filteredProjectNumber.ordinalNumber === ordinalNumber
      );
      return filteredOrdinalNumber[0];
    },
    filteredSetting(projectNumber, ordinalNumber) {
      let filteredProjectNumberList = this.getConditionMonitorList.filter(
        conditionMonitor => conditionMonitor.settingProject === projectNumber
      );
      let filteredOrdinalNumber = filteredProjectNumberList.filter(
        filteredProjectNumber =>
          filteredProjectNumber.settingOrdinal === ordinalNumber
      );
      return filteredOrdinalNumber[0];
    },
    updateConditionMonitorEmit() {
      if (this.validateUpdateConditionMonitor()) {
        this.UPDATE_CONDITION_MONITOR(this.selectedConditionMonitor);
        this.showSettingDialog = false;
        this.$toast.add({
          severity: "success",
          summary: "Success Message",
          detail: "Setting has been updated",
          group: "br",
          life: 3000
        });
        this.showProjectOrdinalMessage = false;
      } else {
        this.showProjectOrdinalMessage = true;
      }
    },
    addConditionMonitorEmit() {
      if (this.validateAddConditionMonitor()) {
        this.ADD_CONDITION_MONITOR(this.newConditionMonitor);
        this.showAddConditionMonitor = false;
        this.$toast.add({
          severity: "success",
          summary: "Success Message",
          detail: "New Condition Monitor has been added",
          group: "br",
          life: 3000
        });
        this.showProjectOrdinalMessage = false;
      } else {
        this.showProjectOrdinalMessage = true;
      }
    },
    // UPDATE OPERATIONS
    settingProjectEmitUOp(val) {
      this.selectedConditionMonitor.settingProject = val;
    },
    settingOrdinalEmitUOp(val) {
      this.selectedConditionMonitor.settingOrdinal = val;
    },
    settingLabelEmitUOp(val) {
      this.selectedConditionMonitor.settingLabel = val;
    },
    settingLocationEmitUOp(val) {
      this.selectedConditionMonitor.settingLocation = val;
    },
    settingGroundFaultAsEmitUOp(val) {
      this.selectedConditionMonitor.settingGroundFaultAs = val;
    },
    settingShortCircuitAsEmitUOp(val) {
      this.selectedConditionMonitor.settingShortCircuitAs = val;
    },
    settingOfflineAsEmitUOp(val) {
      this.selectedConditionMonitor.settingOfflineAs = val;
    },
    settingVoltageUnitEmitUOp(val) {
      this.selectedConditionMonitor.settingVoltageUnit = val;
    },
    settingMeasurementEmitUOp(val) {
      this.selectedConditionMonitor.settingMeasurement = val;
    },
    // ADD OPERATIONS
    settingProjectEmitCOp(val) {
      this.newConditionMonitor.settingProject = val;
    },
    settingOrdinalEmitCOp(val) {
      this.newConditionMonitor.settingOrdinal = val;
    },
    settingLabelEmitCOp(val) {
      this.newConditionMonitor.settingLabel = val;
    },
    settingLocationEmitCOp(val) {
      this.newConditionMonitor.settingLocation = val;
    },
    settingGroundFaultAsEmitCOp(val) {
      this.newConditionMonitor.settingGroundFaultAs = val;
    },
    settingShortCircuitAsEmitCOp(val) {
      this.newConditionMonitor.settingShortCircuitAs = val;
    },
    settingOfflineAsEmitCOp(val) {
      this.newConditionMonitor.settingOfflineAs = val;
    },
    settingVoltageUnitEmitCOp(val) {
      this.newConditionMonitor.settingVoltageUnit = val;
    },
    settingMeasurementEmitCOp(val) {
      this.newConditionMonitor.settingMeasurement = val;
    },
    // VALIDATION
    validateAddConditionMonitor() {
      let filtered = this.getConditionMonitorList.filter(cms => {
        return (
          cms.settingProject === this.newConditionMonitor.settingProject &&
          cms.settingOrdinal === this.newConditionMonitor.settingOrdinal
        );
      });
      return filtered.length <= 0;
    },
    validateUpdateConditionMonitor() {
      let currentIdx = this.getConditionMonitorList
        .map(cms => {
          return cms.id;
        })
        .indexOf(this.selectedConditionMonitor.id);

      let filtered = this.getConditionMonitorList.filter((cms, idx) => {
        return (
          cms.settingProject === this.selectedConditionMonitor.settingProject &&
          cms.settingOrdinal === this.selectedConditionMonitor.settingOrdinal &&
          currentIdx !== idx
        );
      });
      return filtered.length <= 0;
    },
    deleteConditionMonitorEmit() {
      this.DELETE_CONDITION_MONITOR_BY_ID(this.selectedConditionMonitor.id);
      this.showSettingDialog = false;
      this.$toast.add({
        severity: "success",
        summary: "Success Message",
        detail: "Condition Monitor has been deleted",
        group: "br",
        life: 3000
      });
    }
  },
  mounted() {
    this.GET_ALL_CONDITION_MONITOR_SETTINGS();
  }
};
</script>

<style scoped>
.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: transparent;
  border-radius: 50px;
  text-align: center;
}
</style>