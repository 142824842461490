import axios from "../../plugin/axios/axios";
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

//state
const state = {
    conditionMonitorList: [],
    conditionMonitor: {},
    operationMessage: "",
    data: {},
    socket: {
        isConnected: false,
        message: '',
        reconnectError: false,
    }
};

// getters
const getters = {
    getConditionMonitorList(state) {
        return state.conditionMonitorList;
    },
    getConditionMonitor(state) {
        return state.conditionMonitor;
    },
    getData(state) {
        return state.data;
    },
    getOperationMessage(state) {
        return state.operationMessage;
    }
};

// actions
const actions = {
    GET_ALL_CONDITION_MONITOR_SETTINGS({ commit }) {
        return axios
            .get(process.env.VUE_APP_CONDITION_MONITOR_URL + "/settings")
            .then((response) => {
                response.data.data.forEach(data => {
                    data.status = ""
                })
                commit("mutateConditionMonitorList", response.data.data);
                return response;
            });
    },
    UPDATE_CONDITION_MONITOR({ commit }, newConditionMonitor) {
        return axios
            .post(process.env.VUE_APP_CONDITION_MONITOR_URL + "/update", newConditionMonitor)
            .then((response) => {
                let matchId = -1
                state.conditionMonitorList.forEach((cm, idx) => {
                    if (cm.id == newConditionMonitor.id) matchId = idx
                })

                Object.keys(newConditionMonitor).forEach(key => {
                    state.conditionMonitorList[matchId][key] = newConditionMonitor[key]
                })

                return response;
            })
            .catch(err => {
                commit("mutateOperationMessage", err.response.data.message)
                return err.response;
            })
    },
    ADD_CONDITION_MONITOR({ commit }, newConditionMonitor) {
        return axios
            .post(process.env.VUE_APP_CONDITION_MONITOR_URL + "/add", newConditionMonitor)
            .then((response) => {
                commit("addConditionMonitor", newConditionMonitor)
                return response;
            })
            .catch(err => {
                commit("mutateOperationMessage", err.response.data.message)
                return err.response;
            })
    },
    DELETE_CONDITION_MONITOR_BY_ID({ commit }, id) {
        return axios
            .delete(process.env.VUE_APP_CONDITION_MONITOR_URL + "/delete/" + id)
            .then((response) => {
                commit("mutateConditionMonitorList", state.conditionMonitorList.filter(conditionMonitor => {
                    return conditionMonitor.id !== id
                }))
                return response;
            })
            .catch(err => {
                commit("mutateOperationMessage", err.response.data.message)
                return err.response;
            })
    },
    SELECT_CONDITION_MONITOR({ commit }, selectedConditionMonitor) {
        commit("mutateSelectedConditionMonitor", selectedConditionMonitor)
    },
    clearConditionMonitorStore({ commit }) {
        commit("clear")
    }
};


// mutations
const mutations = {
    SOCKET_ONOPEN(state, event) {
        Vue.prototype.$socket = event.currentTarget
        state.socket.isConnected = true
        //console.log("Socket connected ", event)
    },
    SOCKET_ONCLOSE(state) {
        state.socket.isConnected = false
        // console.log("Socket closed ", event)
    },
    SOCKET_ONERROR(state, event) {
        console.error(state, event)
    },
    SOCKET_ONMESSAGE(state, message) {
        state.socket.message = message
        let dataParse = JSON.parse(message.data)
        if (dataParse.type === "data") {
            // if (state.data.length > state.arraySize) {
            //     state.data.shift()
            // }
            // state.data.push(test.value)
            state.data = dataParse.value
        }
    },
    SOCKET_RECONNECT(state, count) {
        console.info(state, count)
    },
    SOCKET_RECONNECT_ERROR(state) {
        state.socket.reconnectError = true;
    },
    mutateConditionMonitorList: (state, conditionMonitorList) => {
        state.conditionMonitorList = conditionMonitorList;
    },
    addConditionMonitor: (state, conditionMonitor) => {
        state.conditionMonitorList.push(conditionMonitor);
    },
    mutateSelectedConditionMonitor: (state, selectedConditionMonitor) => {
        state.selectedConditionMonitor = selectedConditionMonitor
    },
    mutateOperationMessage: (state, message) => {
        state.operationMessage = message
    },
    clear: (state) => {
        state.conditionMonitorList = []
        state.selectedConditionMonitor = {}
        state.operationMessage = ""
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
