import Vue from 'vue';
import Vuex from 'vuex';

import ConditionMonitorStore from "./ConditionMonitor/ConditionMonitorStore";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        socket: {
            isConnected: false,
            message: '',
            reconnectError: false,
        },
        data: []
    },
    modules: {
        ConditionMonitorStore
    },
    mutations: {
        SOCKET_ONOPEN(state) {
            Vue.prototype.$socket = event.currentTarget
            state.socket.isConnected = true
            //console.log("Socket connected ", event)
        },
        SOCKET_ONCLOSE(state) {
            state.socket.isConnected = false
            // console.log("Socket closed ", event)
        },
        SOCKET_ONERROR() {
            // console.error(state, event)
        },
        // default handler called for all methods
        SOCKET_ONMESSAGE(state, message) {
            state.socket.message = message
            // Check if Object or message status
            if (message.data[0] === "{") {
                let dataStream = JSON.parse(message.data)
                if (dataStream.type === "initialData") {
                    state.data = dataStream.value
                } else {
                    if (dataStream.type === "data") {
                        let matchedIdx = -1;
                        let filteredCM = state.data.filter((conditionMonitor, idx) => {
                            if ((conditionMonitor.projectNumber === dataStream.value.projectNumber) && (conditionMonitor.ordinalNumber === dataStream.value.ordinalNumber)) {
                                matchedIdx = idx
                                return true
                            } else {
                                return false
                            }
                        })


                        let keyArray = dataStream.value.key.split(".")
                        let parentKey = keyArray[0]

                        if (filteredCM.length === 0) {
                            // INSERT NEW CONDITION MONITOR
                            let conditionMonitorTemp = {
                                id: 1,
                                frequency: "",
                                effectivePower: "",
                                reactivePower: "",
                                status: "ok",
                                apparentPower: "",
                                totalPowerFactor: "",
                                ordinalNumber: "",
                                projectNumber: "",
                                L1: {
                                    voltage: "",
                                    current: "",
                                    cosinephi: "",
                                    direction: "",
                                    phaseStatus: "ok"
                                },
                                L2: {
                                    voltage: "",
                                    current: "",
                                    cosinephi: "",
                                    direction: "",
                                    phaseStatus: "ok"
                                },
                                L3: {
                                    voltage: "",
                                    current: "",
                                    cosinephi: "",
                                    direction: "",
                                    phaseStatus: "ok"
                                }
                            }

                            conditionMonitorTemp.projectNumber = dataStream.value.projectNumber
                            conditionMonitorTemp.ordinalNumber = dataStream.value.ordinalNumber

                            if (keyArray.length > 1) {
                                let childData = {}
                                let childKey = keyArray[1]
                                childData[childKey] = dataStream.value.value
                                conditionMonitorTemp[parentKey] = {}
                                conditionMonitorTemp[parentKey] = childData
                            } else {
                                conditionMonitorTemp[parentKey] = dataStream.value.value
                            }
                            state.data.push(conditionMonitorTemp)
                        }
                        else {
                            // UPDATING EXSISTING CONDITION MONITOR
                            let matchedCM = filteredCM[0]
                            if (keyArray.length > 1) { //IF HAS CHILD LAYER
                                //UPDATE THE CHILD VALUE
                                let childKey = keyArray[1]
                                matchedCM[parentKey][childKey] = dataStream.value.value
                                state.data[matchedIdx][parentKey] = matchedCM[parentKey]




                                if (matchedCM.L1.phaseStatus === "short circuit" || matchedCM.L2.phaseStatus === "short circuit" || matchedCM.L3.phaseStatus === "short circuit")
                                    state.data[matchedIdx]["status"] = "short circuit"


                                if (matchedCM.L1.phaseStatus === "earth fault" || matchedCM.L2.phaseStatus === "earth fault" || matchedCM.L3.phaseStatus === "earth fault")
                                    state.data[matchedIdx]["status"] = "earth fault"


                                if (matchedCM.L1.phaseStatus === "ok" && matchedCM.L2.phaseStatus === "ok" && matchedCM.L3.phaseStatus === "ok")
                                    state.data[matchedIdx]["status"] = "ok"

                            } else { // IF IN PARENT LAYER
                                //UPDATE THE PARENT VALUE
                                state.data[matchedIdx][parentKey] = dataStream.value.value
                            }
                        }
                    }
                }
            }
        },
        SOCKET_RECONNECT() {
            //console.info(state, count)
        },
        SOCKET_RECONNECT_ERROR(state) {
            state.socket.reconnectError = true;
        }
    },
    getters: {
        data: state => state.data
    }
})