import Vue from 'vue'
import App from './App.vue'
import PrimeVue from 'primevue/config';
import store from '@/store';
import VueLogger from 'vuejs-logger';
import VueNativeSock from 'vue-native-websocket';
import '@/styles.css';

let websocketUrl = 'wss://' + location.host + process.env.VUE_APP_WEBSOCKET_DATASTREAM_URL
if(process.env.NODE_ENV === 'development') {
  websocketUrl = process.env.VUE_APP_WEBSOCKET_DATASTREAM_URL
}

Vue.use(VueNativeSock, websocketUrl, {
  store: store, 
  reconnection: true,
  reconnectionAttempts: 0,
  reconnectionDelay: 3000
})
Vue.config.productionTip = false

Vue.use(PrimeVue);
Vue.use(VueLogger);

import 'primeflex/primeflex.css'
import "primevue/resources/themes/saga-blue/theme.css"

import "primevue/resources/primevue.min.css"                //core css
import "primeicons/primeicons.css"

import ToastService from 'primevue/toastservice';
Vue.use(ToastService);

import Toast from 'primevue/toast';
Vue.component('Toast', Toast);

import Card from 'primevue/card';            //  PRIMEVUE
Vue.component('Card', Card);

import InputText from 'primevue/inputtext';
Vue.component('InputText', InputText);

import Button from 'primevue/button';
Vue.component('Button', Button);

import Dialog from 'primevue/dialog';
Vue.component('Dialog', Dialog);

import SpeedDial from 'primevue/speeddial';
Vue.component('SpeedDial', SpeedDial);

new Vue({
  store,
  render: h => h(App),
}).$mount('#app')







