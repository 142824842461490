import axios from 'axios';
axios.defaults.headers['Content-Type'] = 'application/json';

// axios.interceptors.request.use(
	// function (config) {
	// 	const applicationToken = localStorage.getItem("applicationToken");
	// 	config.headers['Authorization'] = `Bearer ${applicationToken}`;
	// 	return config;
	// },
	// function (error) {
		// Store.dispatch('LoadOperations/END');
	// 	return Promise.reject(error);
	// }
// );

export default axios;
