<template>
  <div id="app">
    <ConditionMonitorDashboard />
    <!-- <settings /> -->
  </div>
</template>



 <script>
import ConditionMonitorDashboard from "./views/ConditionMonitorDashboard";
// import settings from "./views/settings"

export default {
  name: "app",
  components: {
    ConditionMonitorDashboard,
    // settings
  },
  data() {
    return {
      showDisplay: true
    };
  }
};
</script>


 <style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
