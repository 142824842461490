<template>
  <div>
    <Card style="width: 420px; min-width: 420px;">
      <template #content>
        <div class="p-grid p-nogutter">
          <div class="p-col">
            <div
              style="
                    margin-bottom: -6px;
                    height: 60px;"
            >
              <InputText class="locationLabel" v-model="label" :disabled="true" />
            </div>
          </div>

          <div class="p-col-fixed p-nogutter" style="width: 50px;" v-if="configurationAllowed">
            <button
              @click="actionShowSettings"
              class="pi pi-cog"
              style="
                          background-color: transparent;
                          /*margin-right: -163px; */
                          width: 50px;
                          height: 53px;
                          font-size: 30px;
                          border: none;
                          color: grey;"
            ></button>
          </div>
        </div>

        <div style="height: 60px;
                  margin-bottom: -8px;">
          <InputText class="locationLabel" v-model="location" :disabled="true"/>
        </div>

        <div class="p-grid p-nogutter">
          <div class="p-col">
            <div class="mediumContainer" style="margin-right: 1px">
              <div>Total Current</div>
              <InputText class="boxText" v-model="totalCurrentComputed" />
            </div>
          </div>

          <div class="p-col p-nogutter">
            <div class="mediumContainer" style="margin-right: 1px">
              <div>Cosphi</div>
              <InputText class="boxText" v-model="streamData.totalPowerFactor" />
            </div>
          </div>

          <div class="p-col">
            <div class="mediumContainer">
              <div>Frequency</div>
              <div class="boxText">{{streamData.frequency}}Hz</div>
            </div>
          </div>
        </div>

        <div class="p-grid p-nogutter">
          <div class="p-col">
            <div class="mediumContainer" ref="statusL1">
              <!-- background:rgb(250,180,180); -->
              <div class="L-label">L1</div>
              <div
                class="boxText"
              >{{getVoltageByUnit(streamData.L1.voltage)}}{{conditionMonitorSetting.settingVoltageUnit}}</div>
              <div class="boxText">{{getValueInInteger(streamData.L1.current)}}A</div>
              <div class="boxText">{{streamData.L1.cosinephi}}</div>
              <div class="baseArrows">
                <div class="arrow-left" ref="arrowL1LeftRef">
                  <span>B</span>
                </div>
                <div class="arrow-right" ref="arrowL1RightRef">
                  <span>A</span>
                </div>
              </div>
            </div>
          </div>

          <div class="p-col p-nogutter">
            <div class="mediumContainer" ref="statusL2">
              <div class="L-label">L2</div>
              <div
                class="boxText"
              >{{getVoltageByUnit(streamData.L2.voltage)}}{{conditionMonitorSetting.settingVoltageUnit}}</div>
              <div class="boxText">{{getValueInInteger(streamData.L2.current)}}A</div>
              <div class="boxText">{{streamData.L2.cosinephi}}</div>
              <div class="baseArrows">
                <div class="arrow-left" ref="arrowL2LeftRef">
                  <span>B</span>
                </div>
                <div class="arrow-right" ref="arrowL2RightRef">
                  <span>A</span>
                </div>
              </div>
            </div>
          </div>

          <div class="p-col">
            <div class="mediumContainer" ref="statusL3">
              <div class="L-label">L3</div>
              <div
                class="boxText"
              >{{getVoltageByUnit(streamData.L3.voltage)}}{{conditionMonitorSetting.settingVoltageUnit}}</div>
              <div class="boxText">{{getValueInInteger(streamData.L3.current)}}A</div>
              <div class="boxText">{{streamData.L3.cosinephi}}</div>
              <div class="baseArrows">
                <div class="arrow-left" ref="arrowL3LeftRef">
                  <span>B</span>
                </div>
                <div class="arrow-right" ref="arrowL3RightRef">
                  <span>A</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button class="boxStatusButton" ref="btnStatus" style="background: grey;">
          <p
            class="buttonText"
          >{{ streamData.status === "" ? "OFFLINE" : streamData.status.toUpperCase() }}</p>
        </button>

        <div class="p-grid p-nogutter">
          <div class="p-col">
            <div class="mediumContainer" style="margin-right: 1px">
              <div>Effective Power</div>
              <div class="boxText">{{getValueInInteger(streamData.effectivePower)}}kW</div>
            </div>
          </div>

          <div class="p-col p-nogutter">
            <div class="mediumContainer" style="margin-right: 1px">
              <div>Reactive Power</div>
              <div class="boxText">{{getValueInInteger(streamData.reactivePower)}}kVAr</div>
            </div>
          </div>

          <div class="p-col">
            <div class="mediumContainer">
              <div>Apparent Power</div>
              <div class="boxText">{{getValueInInteger(streamData.apparentPower)}}kVA</div>
            </div>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>


<script>
export default {
  components: {},
  data() {
    return {
      cosphi: "0,0°",
      frequency: "0,00Hz",
      effectivePower: "0,0kW",
      reactivePower: "0,0kVar",
      apparentPower: "0,0kVA",
      configurationAllowed: true
    };
  },
  watch: {
    streamData: {
      handler(val) {
        // STATUS BOX
        if (val.status === "ok") {
          this.$refs.btnStatus.style.background = "green";
        } else if (val.status === "offline") {
          this.$refs.btnStatus.style.background = "grey";
        } else if (val.status === "ground fault") {
          this.$refs.btnStatus.style.background = "yellow";
        } else if (val.status === "earth fault") {
          this.$refs.btnStatus.style.background = "red";
        } else if (val.status === "short circuit") {
          this.$refs.btnStatus.style.background = "orange";
        } else if (val.status === "") {
          this.$refs.btnStatus.style.background = "grey";
        }

        // L1 BOX
        if (val.L1.phaseStatus === "ok")
          this.$refs.statusL1.style.background = "";
        else if (val.L1.phaseStatus === "earth fault")
          this.$refs.statusL1.style.background = "red";
        else if (val.L1.phaseStatus === "short circuit")
          this.$refs.statusL1.style.background = "orange";

        // L2 BOX
        if (val.L2.phaseStatus === "ok")
          this.$refs.statusL2.style.background = "";
        else if (val.L2.phaseStatus === "earth fault")
          this.$refs.statusL2.style.background = "red";
        else if (val.L2.phaseStatus === "short circuit")
          this.$refs.statusL2.style.background = "orange";

        // L3 BOX
        if (val.L3.phaseStatus === "ok")
          this.$refs.statusL3.style.background = "";
        else if (val.L3.phaseStatus === "earth fault")
          this.$refs.statusL3.style.background = "red";
        else if (val.L3.phaseStatus === "short circuit")
          this.$refs.statusL3.style.background = "orange";

        // ARROW
        this.arrowStylingGenerator(val, "L1");
        // L2
        this.arrowStylingGenerator(val, "L2");
        // L3
        this.arrowStylingGenerator(val, "L3");
      },
      deep: true
    }
  },
  computed: {
    totalCurrentComputed() {
      if (
        this.streamData.L1.current === "" &&
        this.streamData.L2.current === "" &&
        this.streamData.L3.current === ""
      )
        return "";
      else
        return (
          parseInt(this.streamData.L1.current) +
          parseInt(this.streamData.L2.current) +
          parseInt(this.streamData.L3.current) +
          " A"
        );
    },

  },
  props: {
    label: {
      type: String,
      default: ""
    },
    location: {
      type: String,
      default: ""
    },
    componentNumber: {
      type: Number,
      default: -1
    },
    status: {
      type: String,
      default: ""
    },
    conditionMonitorSetting: {
      type: Object,
      default: () => ({
        settingGroundFaultAs: "",
        settingLabel: "",
        settingLocation: "",
        settingMeasurement: "",
        settingOfflineAs: "",
        settingOrdinal: "",
        settingProject: "",
        settingShortCircuitAs: "",
        settingVoltageUnit: "",
        status: ""
      })
    },
    streamData: {
      type: Object,
      default: () => ({
        id: -1,
        frequency: "",
        effectivePower: "",
        reactivePower: "",
        status: "offline",
        apparentPower: "",
        totalPowerFactor: "",
        ordinalNumber: "",
        projectNumber: "",
        L1: {
          voltage: "",
          current: "",
          cosinephi: "",
          direction: "",
          phaseStatus: "ok"
        },
        L2: {
          voltage: "",
          current: "",
          cosinephi: "",
          direction: "",
          phaseStatus: "ok"
        },
        L3: {
          voltage: "",
          current: "",
          cosinephi: "",
          direction: "",
          phaseStatus: "ok"
        }
      })
    }
  },
  methods: {
    arrowStylingGenerator(val, L) {
      let refTemp = "arrow" + L;
      this.$refs[refTemp + "LeftRef"].style.display = "inline-block";
      this.$refs[refTemp + "LeftRef"].style.marginTop = "5px";
      this.$refs[refTemp + "LeftRef"].style.marginBottom = "45px";
      this.$refs[refTemp + "LeftRef"].style.width = "0";
      this.$refs[refTemp + "LeftRef"].style.height = "0";
      this.$refs[refTemp + "LeftRef"].style.borderTop =
        "20px solid transparent";
      this.$refs[refTemp + "LeftRef"].style.borderBottom =
        "20px solid transparent";

      if (val[L].direction === "") {
        this.$refs[refTemp + "LeftRef"].style.borderRight = "40px solid grey";
        this.$refs[refTemp + "RightRef"].style.borderLeft = "40px solid grey";
      } else if (val[L].direction === "A") {
        this.$refs[refTemp + "LeftRef"].style.borderRight = "40px solid grey";
        this.$refs[
          refTemp + "RightRef"
        ].style.borderLeft = this.phaseColorGenerator(val[L].phaseStatus);
      } else {
        this.$refs[refTemp + "RightRef"].style.borderLeft = "40px solid grey";
        this.$refs[
          refTemp + "LeftRef"
        ].style.borderRight = this.phaseColorGenerator(val[L].phaseStatus);

        this.$refs[refTemp + "RightRef"].style.marginLeft = "20px";
      }
    },
    phaseColorGenerator(val) {
      let phaseColor = "";
      switch (val) {
        case "short circuit":
          phaseColor = "40px solid orange";
          break;
        case "earth fault":
          phaseColor = "40px solid red";
          break;
        case "ok":
          phaseColor = "40px solid green";
          break;
        default:
          phaseColor = "40px solid grey";
      }
      return phaseColor;
    },
    getVoltageByUnit(value) {
      if (this.conditionMonitorSetting.settingVoltageUnit === "kV")
        return parseFloat(value / 1000).toFixed(2);
      else{
        if (this.conditionMonitorSetting.settingVoltageUnit === "V" && value !== "")
          return parseInt(value);
        else
          return "";
      }
    },
    getValueInInteger(value){
      if (value === "")
        return "";
      return parseInt(value)
    },
    actionShowSettings() {
      let temp = JSON.parse(JSON.stringify(this.conditionMonitorSetting));
      let data = {
        conditionMonitorSetting: temp,
        value: true
      };
      this.$emit("emitShowSettings", data);
    }
  },
  mounted() {
  }
};
</script>


<style scoped>
input,
select {
  display: block;
  text-align: center;
  font-weight: bold;
  width: 100%;
  border: 1px solid;
  color: #555;
}

.buttonText {
  font-size: 20px;
  font-weight: bold;
  color: white;
}

.locationLabel {
  font-size: 24px;
  font-weight: bold;
  color: #555;
  text-align: center;
}

.L-label {
  font-weight: bold;
  font-size: 20px;
}

.mediumContainer {
  background-color: #eeeeee;
  margin-top: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.boxText {
  margin: 8px;
  width: 90%;
  height: 36px;
  font-size: 18px;
  font-weight: normal;
  color: #555;
  padding: 7px 0;
  border-style: solid;
  border-width: thin;
  background-color: white;
  text-align: center;
  vertical-align: middle;
}



.boxStatusButton {
  background-color: #029444;
  padding: 0;
  width: 100%;
  border-style: hidden;
}

.baseArrows {
  margin-top: 8px;
  margin-left: 8px;
  margin-right: 50px;
  width: 90%;
  height: 50px;
  padding: 0;
  border-style: solid;
  border-width: thin;
  position: relative;
  background-color: white;
}

.arrow-left {
  display: inline-block;
  margin-top: 5px;
  margin-bottom: 45px;
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  /* border-right: 40px solid grey; */
}

.arrow-left span {
  position: absolute;
  margin-left: 15px;
  top: 12px;
  width: 0;
  padding-left: 10px;
  padding-top: 3px;
  color: white;
  font-weight: bold;
  font-size: 16px;
}

.arrow-right {
  display: inline-block;
  margin-top: 5px;
  margin-bottom: 45px;
  margin-left: 20px;
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  /* border-left: 40px solid grey; */
}

.arrow-right span {
  position: absolute;
  margin-left: -35px;
  top: 12px;
  width: 10px;
  padding-right: 0;
  padding-top: 3px;
  color: white;
  font-weight: bold;
  font-size: 16px;
}
</style>